export const articleItemsVersionA = {
  A1: {
    picture: 'Tasse.webp',
    id: 'A1',
    articleId: 'A1',
    description: 'langkey.tnp-shop-article-1',
    price: {
      price: 2.50,
    },
    oldPrice: {
      price: 2.50,
    },
    stock: 10,
    sourceName: 'tyres-n-parts',
    isVersionA: true,
  },
  A2: {
    picture: 'Taschenlampe.webp',
    id: 'A2',
    articleId: 'A2',
    description: 'langkey.tnp-shop-article-2',
    price: {
      price: 14.40,
    },
    oldPrice: {
      price: 14.40,
    },
    stock: 10,
    sourceName: 'tyres-n-parts',
    isVersionA: true,
  },
  A3: {
    picture: 'Regenschirm.webp',
    id: 'A3',
    articleId: 'A3',
    description: 'langkey.tnp-shop-article-3',
    price: {
      price: 13.00,
    },
    oldPrice: {
      price: 13.00,
    },
    stock: 10,
    sourceName: 'tyres-n-parts',
    isVersionA: true,
  },
  A4: {
    picture: 'Kappe.webp',
    id: 'A4',
    articleId: 'A4',
    description: 'langkey.tnp-shop-article-4',
    price: {
      price: 2.50,
    },
    oldPrice: {
      price: 2.50,
    },
    stock: 10,
    sourceName: 'tyres-n-parts',
    isVersionA: true,
  },
};