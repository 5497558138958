<template>
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.65077 12.8332C11.044 12.8332 12.984 10.8931 12.984 8.49992C12.984 6.10673 11.044 4.16666 8.65077 4.16666C6.25757 4.16666 4.3175 6.10673 4.3175 8.49992C4.3175 10.8931 6.25757 12.8332 8.65077 12.8332Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M8.81728 3.00102C8.99367 3.00011 9.16257 2.92964 9.28729 2.80491C9.41202 2.68019 9.48251 2.51129 9.48342 2.3349V0.666128C9.48342 0.489461 9.41323 0.320022 9.28831 0.1951C9.16338 0.070178 8.99395 0 8.81728 0C8.64061 0 8.47117 0.070178 8.34625 0.1951C8.22133 0.320022 8.15118 0.489461 8.15118 0.666128V2.3349C8.1521 2.51129 8.22254 2.68019 8.34727 2.80491C8.47199 2.92964 8.64089 3.00011 8.81728 3.00102Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M5.13275 3.41038C5.2219 3.56319 5.36793 3.67451 5.53889 3.72002C5.70986 3.76552 5.89187 3.74151 6.0452 3.65324C6.19708 3.5641 6.30772 3.41873 6.35317 3.2486C6.39863 3.07846 6.37522 2.89727 6.28804 2.74426L5.45542 1.30099C5.36751 1.14783 5.22235 1.03584 5.0519 0.989651C4.88145 0.94346 4.69966 0.96685 4.54644 1.05467C4.39304 1.14325 4.28099 1.28899 4.23482 1.46001C4.18865 1.63103 4.2121 1.81338 4.30008 1.96712L5.13275 3.41038Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M3.72774 4.69396L2.28447 3.86131C2.13114 3.77303 1.94913 3.74903 1.77816 3.79453C1.60719 3.84004 1.46117 3.95135 1.37202 4.10416C1.28403 4.2579 1.26054 4.44025 1.30671 4.61127C1.35288 4.78229 1.46493 4.92803 1.61833 5.01661L3.06161 5.84926C3.21481 5.9376 3.39682 5.96145 3.56762 5.91558C3.73841 5.86971 3.88399 5.75788 3.97232 5.60468C4.06065 5.45147 4.08451 5.26946 4.03864 5.09867C3.99277 4.92787 3.88095 4.78229 3.72774 4.69396Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M3.15179 8.32659C3.15088 8.1502 3.08039 7.9813 2.95566 7.85658C2.83094 7.73185 2.66203 7.66137 2.48565 7.66046H0.816895C0.640228 7.66046 0.47079 7.73065 0.345868 7.85557C0.220945 7.98049 0.150757 8.14992 0.150757 8.32659C0.150757 8.50326 0.220945 8.67268 0.345868 8.79761C0.47079 8.92253 0.640228 8.99272 0.816895 8.99272H2.48565C2.66203 8.9918 2.83094 8.92132 2.95566 8.7966C3.08039 8.67187 3.15088 8.50297 3.15179 8.32659Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M2.89504 10.8626L1.45176 11.6953C1.2986 11.7832 1.18663 11.9283 1.14044 12.0988C1.09425 12.2692 1.11763 12.4511 1.20545 12.6043C1.29403 12.7577 1.43977 12.8697 1.61079 12.9159C1.78181 12.9621 1.96416 12.9386 2.1179 12.8506L3.56114 12.018C3.71434 11.9296 3.82621 11.784 3.87208 11.6132C3.91795 11.4425 3.89409 11.2604 3.80576 11.1072C3.71742 10.954 3.57184 10.8422 3.40105 10.7963C3.23026 10.7505 3.04824 10.7743 2.89504 10.8626Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M5.75728 13.1837C5.60396 13.0954 5.4219 13.0714 5.25093 13.1169C5.07996 13.1624 4.93398 13.2737 4.84483 13.4265L4.01217 14.8698C3.92389 15.0231 3.89987 15.2051 3.94538 15.3761C3.99088 15.5471 4.10219 15.6931 4.25501 15.7822C4.40875 15.8702 4.5911 15.8937 4.76212 15.8475C4.93313 15.8014 5.07888 15.6893 5.16746 15.5359L6.00012 14.0926C6.0873 13.9396 6.11071 13.7585 6.06525 13.5883C6.0198 13.4182 5.90916 13.2728 5.75728 13.1837Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M8.48422 13.9989C8.30784 13.9998 8.13894 14.0703 8.01421 14.195C7.88949 14.3198 7.819 14.4887 7.81808 14.665V16.3338C7.81808 16.5105 7.88827 16.6799 8.0132 16.8049C8.13812 16.9298 8.30756 17 8.48422 17C8.66089 17 8.83033 16.9298 8.95525 16.8049C9.08017 16.6799 9.15032 16.5105 9.15032 16.3338V14.665C9.14941 14.4887 9.07896 14.3198 8.95423 14.195C8.82951 14.0703 8.66061 13.9998 8.48422 13.9989Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M12.1687 13.5896C12.0796 13.4367 11.9335 13.3254 11.7626 13.2799C11.5916 13.2344 11.4096 13.2584 11.2563 13.3467C11.1044 13.4358 10.9937 13.5812 10.9483 13.7513C10.9028 13.9215 10.9262 14.1027 11.0134 14.2557L11.846 15.6989C11.934 15.8521 12.0791 15.9641 12.2496 16.0103C12.42 16.0565 12.6018 16.0331 12.755 15.9453C12.9084 15.8567 13.0205 15.7109 13.0666 15.5399C13.1128 15.3689 13.0894 15.1866 13.0014 15.0328L12.1687 13.5896Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M15.6832 11.9833L14.2399 11.1506C14.0867 11.0623 13.9047 11.0384 13.7339 11.0843C13.5631 11.1302 13.4176 11.242 13.3292 11.3952C13.2409 11.5484 13.217 11.7304 13.2629 11.9012C13.3088 12.072 13.4206 12.2176 13.5738 12.3059L15.0171 13.1386C15.1704 13.2269 15.3524 13.2509 15.5234 13.2054C15.6944 13.1599 15.8404 13.0486 15.9295 12.8957C16.0175 12.742 16.041 12.5596 15.9948 12.3886C15.9487 12.2176 15.8366 12.0719 15.6832 11.9833Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M16.4846 8.00046H14.8159C14.6392 8.00046 14.4698 8.07064 14.3449 8.19556C14.2199 8.32048 14.1497 8.48992 14.1497 8.66659C14.1497 8.84325 14.2199 9.01268 14.3449 9.1376C14.4698 9.26252 14.6392 9.3327 14.8159 9.3327H16.4846C16.6613 9.3327 16.8307 9.26252 16.9557 9.1376C17.0806 9.01268 17.1508 8.84325 17.1508 8.66659C17.1508 8.48992 17.0806 8.32048 16.9557 8.19556C16.8307 8.07064 16.6613 8.00046 16.4846 8.00046Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M14.4064 6.13722L15.8497 5.30457C16.0029 5.21666 16.1149 5.07153 16.161 4.90107C16.2072 4.73062 16.1839 4.54881 16.096 4.39559C16.0075 4.24219 15.8617 4.13013 15.6907 4.08397C15.5197 4.0378 15.3373 4.06128 15.1836 4.14926L13.7403 4.98191C13.5871 5.07024 13.4753 5.21582 13.4294 5.38662C13.3835 5.55741 13.4074 5.73942 13.4957 5.89263C13.5841 6.04583 13.7296 6.15768 13.9004 6.20355C14.0712 6.24942 14.2532 6.22556 14.4064 6.13722Z"
      :fill="color"
      class="svg-icon-fill"
    />
    <path
      d="M11.5442 3.81637C11.6975 3.90465 11.8796 3.92865 12.0505 3.88315C12.2215 3.83764 12.3675 3.72632 12.4566 3.57351L13.2893 2.13025C13.3776 1.97693 13.4016 1.79489 13.3561 1.62393C13.3106 1.45296 13.1993 1.30696 13.0465 1.2178C12.8927 1.12982 12.7104 1.10634 12.5394 1.15251C12.3683 1.19868 12.2226 1.31072 12.134 1.46413L11.3014 2.90739C11.2142 3.0604 11.1908 3.24159 11.2362 3.41172C11.2817 3.58185 11.3923 3.72724 11.5442 3.81637Z"
      :fill="color"
      class="svg-icon-fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
};
</script>