<template>
  <div>
    <v-card
      rounded="sm"
      style="height: 100%"
      class="news-room"
    >
      <v-card-title class="card__title">
        <h5 class="text">
          {{ $t('langkey.campaigns-promotions') }}
        </h5>
      </v-card-title>
      <v-card-text class="card__content">
        <agile
          ref="carousel"
          class="campaign"
          :options="isTyreSearch ? sliderOptionsIsTyreSearch : sliderOptions"
        >
          <div
            v-for="(item, index) in newsImages"
            :key="index"
            class="slide"
          >
            <v-card
              tile
              class="card__item"
            >
              <a
                v-if="item.link"
                target="_blank"
                :href="item.link"
              >
                <img
                  :class="['img', item.to || item.hasModalContent && 'clickable']"
                  :src="item.image"
                >
              </a>
              <img
                v-else
                :class="['img', item.to || item.hasModalContent || item.articleId && 'clickable']"
                :src="item.image"
                @click="onClickCover(item)"
              >
            </v-card>
          </div>
          <template slot="prevButton">
            <icon-arrow-circle />
          </template>
          <template slot="nextButton">
            <icon-arrow-circle />
          </template>
        </agile>
      </v-card-text>
    </v-card>
    <base-dialog
      ref="simpleDialog"
    >
      <img
        class="img__term-of-service"
        :src="require(`@/assets/images/TNP_Teilnahmebedingungen_TNP-Wochen.png`)"
      >
    </base-dialog>

    <v-dialog
      v-model="showAddToCartDialog"
      max-width="550"
    >
      <v-card class="dialog news-room-add-to-cart">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.cart-dialog-head') }}
          </h5>
          <p class="description">
            {{ $t('langkey.cart-dialog-description') }}
          </p>
        </v-card-title>
        <v-card-actions>
          <v-btn
            class="icon"
            outlined
            plain
            @click="onContinueShopping"
          >
            {{ $t('langkey.cart-dialog-continue') }} <icon-add-to-cart />
          </v-btn>
          <v-btn
            color="primary"
            class="icon"
            @click="onShowCart"
          >
            {{ $t('langkey.cart-dialog-confirm') }} <icon-cart-show color="var(--theme-white)" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/news-room.scss';
</style>

<script>
import IconArrowCircle from '@/components/icons/IconArrowCircle';
import IconCartShow from '@/components/icons/IconCartShow';
import { articleItemsVersionA } from '@/configs/components/showAddToCartDialog';
import { mapState } from 'vuex';

export default {
  components: {
    IconArrowCircle,
    IconCartShow,
  },
  props: {
    isTyreSearch: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      newsImages: [],
      mockNewsImages: [],
      tyreSearchImages: [
        {
          image: require('@/assets/images/Banner_hochkannt.webp'),
          articleId: 'A1',
        },
      ],
      sliderOptions: {
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 10000,
      },
      sliderOptionsIsTyreSearch: {
        slidesToShow: 1,
      },
      showAddToCartDialog: false,
      cartItem: {},
      articleItemsVersionA: articleItemsVersionA,
    };
  },
  computed: {
    ...mapState('cart', ['articlesVersionA']),
    ...mapState('user', ['userInformation']),
    getUserId() {
      return this.userInformation.id;
    },
  },
  created() {
    if (!this.isTyreSearch) {
      const today = new Date();
      const release_date = new Date(2022, 2, 15, 6, 0);
      if (today.getTime() < release_date.getTime()) {
        this.mockNewsImages.push({ image: require('@/assets/images/Banner_football.jpg') });
      } else {
        this.mockNewsImages.push({ image: require('@/assets/images/Tasse.webp'), articleId: 'A1' });
        this.mockNewsImages.push({ image: require('@/assets/images/Taschenlampe.webp'), articleId: 'A2' });
        this.mockNewsImages.push({ image: require('@/assets/images/Regenschirm.webp'), articleId: 'A3' });
        this.mockNewsImages.push({ image: require('@/assets/images/Kappe.webp'), articleId: 'A4' });
      }
      this.newsImages = this.mockNewsImages;
    } else {
      this.newsImages = this.tyreSearchImages;
    }
  },
  methods: {
    onClickCover(item) {
      if (item.to) {
        this.$router.push(this.$i18nRoute({
          name: item.to,
        }));
      } else if (item.hasModalContent) {
        this.onOpenDialog(item.hasModalContent);
      } else if (item.articleId) {
        this.cartItem = this.articleItemsVersionA[item.articleId];
        this.showAddToCartDialog = true;
      }
    },
    onOpenDialog(hasModalContent) {
      if(hasModalContent)
        this.$refs.simpleDialog.show();
    },
    onContinueShopping() {
      this.showAddToCartDialog = false;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const articleVersionADataSession = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
      const articleVersionAData = this.articlesVersionA.length < articleVersionADataSession.length
        ? articleVersionADataSession
        : this.articlesVersionA;
      const articleVersionADataUser = articleVersionAData.filter((item) => item.userId === this.getUserId);
      const hasArticleId = articleVersionADataUser.some((article) => article.id === this.cartItem.id);
      if (!hasArticleId) {
        let payload = {
          ...this.cartItem,
          quantity: 1,
          userId: this.getUserId,
        };
        this.$store.dispatch('cart/addCartArticleVersionA', payload)
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      } else {
        const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];

        let updateArticlesVersionA;
        updateArticlesVersionA = articleVersionAData.reduce((prev,cur) => {
          return cur.id === this.cartItem.id && cur.userId === this.cartItem.userId ?
            prev.concat({
              ...cur,
              quantity: cur.quantity + 1,
            }) :
            prev.concat(cur);
        }, []);
        this.$store.dispatch('cart/updateCartArticleVersionA', updateArticlesVersionA)
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      }
    },
    onShowCart() {
      this.showAddToCartDialog = false;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const articleVersionADataSession = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
      const articleVersionAData = this.articlesVersionA.length < articleVersionADataSession.length
        ? articleVersionADataSession
        : this.articlesVersionA;
      const articleVersionADataUser = articleVersionAData.filter((item) => item.userId === this.getUserId);
      const hasArticleId = articleVersionADataUser.some((article) => article.id === this.cartItem.id);
      if (!hasArticleId) {
        let payload = {
          ...this.cartItem,
          quantity: 1,
          userId: this.getUserId,
        };
        this.$store.dispatch('cart/addCartArticleVersionA', payload)
          .then(() => {
            this.$router.push(
              this.$i18nRoute({
                name: 'cart',
              }),
            );
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      } else {
        const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
        let updateArticlesVersionA;
        updateArticlesVersionA = articleVersionAData.reduce((prev,cur) => {
          return cur.id === this.cartItem.id && cur.userId === this.cartItem.userId ?
            prev.concat({
              ...cur,
              quantity: cur.quantity + 1,
            }) :
            prev.concat(cur);
        }, []);
        this.$store.dispatch('cart/updateCartArticleVersionA', updateArticlesVersionA)
          .then(() => {
            this.$router.push(
              this.$i18nRoute({
                name: 'cart',
              }),
            );
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      }
    },
  },
};
</script>